import React from "react"
import "../pages/index.scss"

import Layout from "../components/layout"
import Head from "../components/Head"

import Content from "src/components/clients/Content/Content"
import {graphql, useStaticQuery} from "gatsby";
import COUNTRY from "../constants";


const TemplateFaq = props => {
  const data = useStaticQuery(graphql`
    query faqPageClientsBy {
      site {
        siteMetadata {
          senderPath
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      },
    }
  `)
  return (
  <div className="faq-page">
    <Head title="Home" titleRu="Главная страница" slug="/faq/" />
    <Layout
      country={COUNTRY.BY}
      location={props.location}
      hotLine={data.site.siteMetadata.countries.by.hotLine}
    >
      <Content block={props.pageContext.block} location={props.location} />
    </Layout>
  </div>
)
}
export default TemplateFaq
